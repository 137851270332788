// 404 Page
export const emptyBG = require("./misc/404-img.png");

// Home Page
export const sampleBanner = require("./home/sample_banner.jpg");
export const sampleIntro1 = require("./home/sample_intro_1.jpg");
export const sampleIntro2 = require("./home/sample_intro_2.jpg");
export const sampleIntro3 = require("./home/sample_intro_3.jpg");

// Forgot Password
export const resetPw = require("./forgot_password/reset_pw.png");

// Misc
export const companyLogo = require("./misc/logo.png");
export const whiteCompanyLogo = require("./misc/white-logo.png");
export const profilePlaceholder = require("./misc/profile-placeholder.png");
export const printIcon = require("./misc/print.png");

// PNG & JPEG
export const uworldCloseIcon = require("./images/close.jpeg");
export const uworldSaveCloseBtn = require("./images/save-close-button.jpeg");
export const uworldDeleteNotesBtn = require("./images/delete-notes-button.jpeg");
export const fontCaseIcon = require("./images/font-case.png");
export const minusIcon = require("./images/minus.png");
export const plusIcon = require("./images/plus.png");
export const restoreWindowIcon = require("./images/restore-window.png");
export const submitBtn = require("./images/submit-btn.png");
export const feedbackIcon = require("./images/feedback.png");
export const paypalIcon = require("./images/paypal.png");
export const creditCardsIcon = require("./images/cards.png");
export const magicWand = require("./images/magic_wand.png");

// SVG Icon
export const searchIcon = require("./svg/search.svg");
export const flashIcon = require("./svg/flash-card.svg");
export const prevTestIcon = require("./svg/prev-test.svg");
export const performanceIcon = require("./svg/performance.svg");
export const createIcon = require("./svg/create.svg");
export const helpIcon = require("./svg/help.svg");
export const homeIcon = require("./svg/home.svg");
export const homeNavIcon = require("./svg/home-nav-bar.svg");
export const noteIcon = require("./svg/note.svg");
export const searchIconW = require("./svg/search-w.svg");
export const flashIconW = require("./svg/flash-card-w.svg");
export const prevTestIconW = require("./svg/prev-test-w.svg");
export const performanceIconW = require("./svg/performance-w.svg");
export const lineChartIconW = require("./svg/line-chart-w.svg");
export const pieChartIconW = require("./svg/pie-chart-w.svg");
export const createIconW = require("./svg/create-w.svg");
export const createIconMain = require("./svg/create-main.svg");
export const deleteIconMain = require("./svg/delete-main.svg");
export const deleteIcon = require("./svg/delete.svg");
export const helpIconW = require("./images/help.png");
export const homeIconW = require("./svg/home-w.svg");
export const noteIconW = require("./svg/note-w.svg");
export const profileIcon = require("./svg/profile.svg");
export const removeIcon = require("./svg/remove.svg");
export const dropdownIcon = require("./misc/dropdown-black.png");
export const dropdownIconW = require("./misc/dropdown-white.png");
export const rightIcon = require("./svg/right.svg");
export const rightIconW = require("./svg/right-w.svg");
export const menuIcon = require("./svg/menu.svg");
export const menuIconW = require("./svg/menu-w.svg");
export const menu1Icon = require("./svg/menu-1.svg");
export const closeIcon = require("./svg/close.svg");
export const checkIcon = require("./svg/check.svg");
export const checkIconW = require("./svg/check-w.svg");
export const cartIcon = require("./svg/cart.svg");
export const upArrowIcon = require("./svg/up-arrow.svg");
export const upArrowIconW = require("./svg/up-arrow-w.svg");
export const sort0Icon = require("./svg/sort-0.svg");
export const sort1Icon = require("./svg/sort-1.svg");
export const sortMainIcon = require("./svg/sort-main.svg");
export const windowIcon = require("./svg/window.svg");
export const close1Icon = require("./svg/close-1.svg");
export const fav0Icon = require("./svg/fav-0.svg");
export const fav1Icon = require("./svg/fav-1.svg");
export const cancelIcon = require("./svg/cancel.svg");
export const correctIcon = require("./svg/correct.svg");
export const flipIcon = require("./svg/flip.svg");
export const newIcon = require("./svg/new.svg");
export const nextIcon = require("./svg/next.svg");
export const flagIcon = require("./svg/flag.svg");
export const flagIconW = require("./svg/flag-w.svg");
export const nextIconC = require("./svg/next-colored.svg");
export const nextDisabledIconC = require("./svg/next-disabled.svg");
export const prevIconC = require("./svg/prev-colored.svg");
export const prevDisabledIconC = require("./svg/prev-disabled.svg");
export const calcIconW = require("./svg/calculator-w.svg");
export const chatIconW = require("./svg/chat-w.svg");
export const contrastIconW = require("./svg/contrast-w.svg");
export const flashIconW2 = require("./svg/flash-w.svg");
export const fontSizeIconW = require("./svg/font-size-w.svg");
export const fullIconW = require("./svg/full-screen-w.svg");
export const exitFullIcon = require("./images/exit-fullscreen.png");
export const labIconW = require("./svg/lab-w.svg");
export const pauseIconW = require("./svg/pause-w.svg");
export const resumeIcon = require("./svg/resume.svg");
export const resumeIconW = require("./svg/resume-w.svg");
export const settingIconW = require("./svg/setting-w.svg");
export const stopIconW = require("./svg/stop-w.svg");
export const whiteboardIconW = require("./svg/whiteboard-w.svg");
export const bookmark0IconW = require("./svg/bookmark0-w.svg");
export const bookmark1IconW = require("./svg/bookmark1-w.svg");
export const importIcon = require("./svg/spreadsheet.svg");
export const topicIcon = require("./svg/topic.svg");
export const questionIcon = require("./svg/question.svg");
export const subjectIcon = require("./svg/subject.svg");
export const userIcon = require("./svg/users.svg");
export const phoneIcon = require("./svg/phone.svg");
export const stepArrowIcon = require("./svg/stepArrow.svg");
export const blackStepArrowIcon = require("./svg/black-stepArrow.svg");
export const backArrowIcon = require("./svg/backArrow.svg");
export const crossIcon = require("./svg/cross.svg");
export const paymentCrossIcon = require("./svg/payment-cross.svg");
export const paymentPlusIcon = require("./svg/payment-plus.svg");
