import { makeStyles } from "@material-ui/styles";

const orange = '#FF5A5F'; 

export default makeStyles((theme) => ({
  pricingSection: {
    padding: theme.spacing(8, 2),
    backgroundColor: '#f5f5f5',
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    fontWeight: 'bold',
    fontSize: '2.5rem',
    color: '#484848',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(4),
  },
  titleLine: {
    width: 100,
    borderTop: `3px solid ${orange}`,
    margin: '0 auto',
    marginBottom: theme.spacing(2),
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(8),
  },
  progressCircle: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.getContrastText(theme.palette.grey[300]),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    margin: theme.spacing(0, 2),
    position: 'relative',

    '&.active': {
      backgroundColor: orange,
    },

    '&.completed': {
      backgroundColor: orange,
    },
    '&.completed:not(:last-child)::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: orange,
      height: '4px',
      width: 40,
      right: '-40px', 
    },
  },
  progressText: {
    fontSize: '0.8rem',
    color: '#484848',
    position: 'absolute',
    top: '40px', 
    whiteSpace: 'nowrap',
  },
  progressLine: {
    flex: 1,
    height: 2,
    backgroundColor: theme.palette.grey[300],
    margin: theme.spacing(0, 2),
  },
}));
